import { Component, Input, OnInit } from '@angular/core';
import { TermsService } from '../../services/terms.service';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-selection-tree-listing',
  templateUrl: './selection-tree-listing.component.html',
  styleUrls: ['./selection-tree-listing.component.scss'],
})
export class SelectionTreeListingComponent implements OnInit {
  @Input() items: any = [];
  @Input() tree: any;
  @Input() context: any;
  @Input() disabled: boolean = false;
  @Input() fromPage: string = "";
  loading: boolean = false
  @Input() userList:any;
  @Input() frequencyList:any;
  termDetails:any;
  monthList:any = [];
  months:any = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  selectedMetric:any;
  selectedMetricObj:any;
  termMetricList:any;
  selectedtermMetricList:any;
  okrTermMetricList:any;
  selectedPeriod: any;
  selectedCompliance: any;
  selectedOwner:any;
  selectedDate:any;
  selectedComment:any;
  documentFile:any;
  documentFilename:any
  constructor(private termService: TermsService, private toastrService: ToastrService,public dialog: MatDialog,
  private spinner: NgxSpinnerService,
    
  ){

  }
  
  ngOnInit(): void {
  }

  async toggleAccordion(item: any, indx: number) {
    item.expanded = !item.expanded;

    if(item.expanded){
      this.context.componentParent.collapse(item);
    }
  }

  getColor(item: any){
    return "";
  }

  selectGoals(item: any){
    if(!this.disabled){
      this.context.componentParent.selectGoals(item);
    }
  }
  emitCommentValue(event:any,item:any,type:any){
    // item.projectterm_id =item.projectterm_id 
    if(type=="comments"){
      item.context = event.target.value;
    }if(type=="frequency"){
      item.frequency = event.value;
    }
    if(type=="due_date"){
      item.due_date = moment(event.target.value).format("YYYY-MM-DD");
    }
    this.context.componentParent.selectGoals(item);
  }

  add(item: any){
    if(item.level == 'category'){
      item.children.push({
        "id": "",
        "term_category_id": item.uuid,
        "term_category": item.id,
        "name": "",
        "display_name":"",
        "is_custom": true,
        "level": "subcategory",
      })
    }else{
      item.children = item?.children ? item.children : []
      item.children.push({
        "id": "",
        "subcategory_id": item.id,
        "name": "",
        "title": "",
        "description":"",
        "is_custom": true,
        "level": "term",
        "category_id": item.category_id,
        "term_subcategory": item.id,
      })
    }
   
  }

  save(item: any){
    item.display_name = item.name;
    this.termService.saveSubcategory(item).subscribe((res: any) => {
      item.id = res.id;
      this.toastrService.success("Added successfully !");
      this.context.componentParent.reload();
    })
  }

  saveTerm(item: any){
    item.title = item.name;
    item.description = item.name;
    this.termService.saveTermcategory(item).subscribe((res: any) => {
      item.id = res.id;
      this.toastrService.success("Added successfully !");
      this.context.componentParent.reload();
    })
  }
  
  //Metrics update
  selectedMatricEvent(e:any){
    this.selectedMetricObj = this.termDetails?.instancemetrics?.filter((item: any) => item.id == e.value)[0];
    this.selectedtermMetricList = this.termMetricList.filter((item:any) => item.id == e.value)[0];
    this.loadMonthDetails();
  }
  onClickMetrics(item:any,modal: any){
    this.selectedMetricObj ="";
    this.selectedPeriod = "";
    this.selectedMetric = "";
    
    this.termDetails = item;
    this.dialog.open(modal, {
      width: '40vw',
    });

    this.spinner.show();
    this.termService.getTermMetrics(this.termDetails?.project_id,this.termDetails?.id).subscribe((res: any) => {
      this.termMetricList = res;
      this.spinner.hide();
    })
  }

  selectedMatricYear(e:any){
    this.loadMonthDetails();
  }
  loadMonthDetails(){
    if(this.selectedPeriod){
      this.monthList = [];
      let monthObj:any = [];
    this.months.forEach((m:any,index:any)=>{
      monthObj.push({month:m,month_id:index+1,value:0,edit:false})
    })
    this.monthList = monthObj;
      let data:any = [];
      let metricYearDetails = this.selectedtermMetricList?.months_data?.filter((item:any)=> item.reporting_year == this.selectedPeriod)[0];
      metricYearDetails.details.forEach((val:any)=>{
        this.monthList.forEach((m:any)=>{
          if(val.reporting_month == m.month_id){
            m.value = val?.current_value
          }
        })
      });
    }
  }
  matricTarVal(){
    let payload:any=[];
    this.monthList.forEach((m:any,index:any)=>{
      payload.push({
          reporting_month: m.month_id,
          reporting_year: this.selectedPeriod,
          instancemetrics_id: this.selectedMetricObj?.instancemetrics,
          current_value: m.value,
          comments: '',
        })
      })
      let data = {
        project_id: this.termDetails?.project_id,
        term_id: this.termDetails?.id,
        termmetrics: payload
    }
    this.spinner.show();
    this.termService.setTermMetrics(data).subscribe((res: any) => {
      this.spinner.hide();
      this.toastrService.success('Metric updated successfully')
      this.dialog.closeAll();
    })
 }

  editColum(i:any){
    this.monthList[i].edit = true;
  }
//Metrics update End
termEvidenceList:any = [];

  onClickEvidence(item:any,modal:any){
    console.log(item);
    this.termEvidenceList=[];
    this.selectedDate = '';
    this.selectedOwner = '';
    this.selectedComment = '';
    this.documentFile = '';
    this.documentFilename = '';
    this.selectedCompliance = '';
    this.dialog.open(modal, {
      width: '40vw',
    });
    this.termDetails = item;
    if(item?.projectterm_id){
      this.spinner.show();
      this.termService.getTermEvidence(item?.projectterm_id).subscribe((res: any) => {
        this.termEvidenceList = res;
        this.spinner.hide();
      })
    }
  }

  onFileSelected(e: any) {
    const inputElement = e.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      
      const file = inputElement.files[0];
      if (inputElement.files[0].size > 5000000) {
        this.toastrService.error('Please upload less than 5MB');
        return;
      }
      if(file){
        this.documentFile = file;
        this.documentFilename = file?.name
      }
    }
  }

  saveEvidence(){
    if(this.selectedDate && this.selectedOwner && this.selectedComment && this.documentFile){
      this.spinner.show();
        this.selectedDate = moment(this.selectedDate).format("YYYY-MM-DD");
        let data= new FormData();
        data.append('file', this.documentFile);
        data.append('term_id',this.termDetails?.id);
        data.append('project_id',this.termDetails?.project_id);
        if(this.termDetails?.projectterm_id){
          data.append('projectterm_id', this.termDetails?.projectterm_id);
        }
        data.append('date', this.selectedDate);
        data.append('owner_id', this.selectedOwner);
        data.append('comment', this.selectedComment);
        this.termService.createTermEvidence(data).subscribe((res: any) => {
          if(res?.projectterm?.id){
            this.termDetails.projectterm_id = res?.projectterm?.id;
            this.context.componentParent.selectGoals(this.termDetails);
          }
          this.termEvidenceList.push(res);
          this.selectedDate = '';
          this.selectedOwner = '';
          this.selectedComment = '';
          this.documentFile = '';
          this.documentFilename = '';
          this.spinner.hide();
        })
    }else{
      this.toastrService.error('Enter all the fields');
    }
  }
  
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TransferState } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class FinancialService {
  constructor(private http: HttpClient, private transferState: TransferState) {}

  getFinanceBudget(projectId: any) {
    let params = `project_id=${projectId}`;
    return this.http.get(`delivery/finance-budget/?${params}`);
  }

  getFinanceSpend(projectId: any, month: any = "") {
    let params = `project_id=${projectId}&month=${month}`;
    return this.http.get(`delivery/finance-spend/?${params}`);
  }

  getCostPoolTaxanomy() {
    return this.http.get("metrics/cost-pool-taxonomy/details/");
  }

  getOuGdpList() {
    return this.http.get("delivery/get-complete-vendor-list/");
  }

  getUserList() {
    return this.http.get("users/user-list/");
  }

  getExecutionTypeList() {
    return this.http.get("base_value/?type=project_execution_type");
  }

  getSpendTypes() {
    return this.http.get("base_value/?type=project_spend_type");
  }

  addFinanceBudget(data: any) {
    return this.http.post("delivery/finance-budget/", data);
  }

  editFinanceBudget(budget_id: any, data: any) {
    return this.http.patch(`delivery/finance-budget/${budget_id}/`, data);
  }

  editProjectDetail(project_id: any, data: any) {
    return this.http.patch(`delivery/project/${project_id}/`, data);
  }

  deleteFinanceBudget(budget_id: any) {
    return this.http.delete(`delivery/finance-budget/${budget_id}/`);
  }

  addFinanceSpend(data: any) {
    return this.http.post("delivery/finance-spend/", data);
  }

  editFinanceSpend(spend_id: any, data: any) {
    return this.http.patch(`delivery/finance-spend/${spend_id}/`, data);
  }

  deleteFinanceSpend(spend_id: any) {
    return this.http.delete(`delivery/finance-spend/${spend_id}/`);
  }

  getProjectPulse(projectId: any) {
    return this.http.get(`nps/pulses/?project=${projectId}`);
  }
}

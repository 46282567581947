import { Component, OnInit, Input } from '@angular/core';
import { IReportEmbedConfiguration, models, Page, Report, service, Embed } from 'powerbi-client';
import { PowerBiService } from 'src/app/shared/services/power-bi.service';


@Component({
  selector: 'app-power-bi-module',
  templateUrl: './power-bi-module.component.html',
  styleUrls: ['./power-bi-module.component.scss']
})
export class PowerBiModuleComponent implements OnInit {
  @Input() page: any;
  reportClass = 'report-container';
  reportModel:IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  }; 
  insightModel = this.reportModel;
  
  constructor(
    private PowerBiService : PowerBiService,
  
  ) { }

  ngOnInit(): void {
    this.getPowerBIReportDetails();
  
  }
  async getPowerBIReportDetails(){
    await this.PowerBiService.getPowerBIReportDetails(this.page).subscribe((res:any)=>{
      let report = res[0];
      this.loadPowerBIReports(report)
    })
  }
  
  loadPowerBIReports(report:any){
    let basicFilter ='';
    this.PowerBiService.getTokenForBI().subscribe((res: any) => {
          this.PowerBiService.getBIEmbedURL(res.access_token,report?.report_id).subscribe((reportData: any) => {
            this.PowerBiService.getBIEmbedToken(res.access_token,report?.report_id,report?.group).subscribe((tokenData: any) => {
              this.insightModel = this.loadPowerBIReportGraph(reportData,tokenData,basicFilter,report?.report_name);
            })
        });
    });
   }
  
   loadPowerBIReportGraph(reportData:any,tokenData:any,basicFilter:any,pageName:any){
    return {type: 'report',
     id: reportData.id,
     embedUrl: reportData.embedUrl,
     accessToken:tokenData.token,
     tokenType: models.TokenType.Embed,
     pageName: pageName,
      filters: [basicFilter],
     settings: {
         panes: {
             filters: {
                 expanded: false,
                 visible: false
             },
             pageNavigation: {
               visible: false
             },
         },
         zoomLevel :0.6,
         background: models.BackgroundType.Transparent,
       }
     }
   }

}

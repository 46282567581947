import { Component, OnInit } from "@angular/core";
import { ScorecardService } from "src/app/shared/services/scorecard.service";
import * as textConfiguration from "src/assets/static-text-configuration.json";
import { Chart } from "angular-highcharts";

@Component({
  selector: "app-individual-scorecard",
  templateUrl: "./individual-scorecard.component.html",
  styleUrls: ["./individual-scorecard.component.scss"],
})
export class IndividualScorecardComponent implements OnInit {
  cards: any;
  staticText: any = (textConfiguration as any).default;
  constructor(private ScorecardService: ScorecardService) {
    this.ScorecardService.cardDetails.subscribe((cards) => {
      this.cards = cards;
    });
  }

  ngOnInit(): void {
    console.log(this.cards.cardDetails);
  }

  clickOnMetric(metric: any, cardDetails: any, cardName: any) {
    let data: any = {
      metric: metric,
      cardDetails: cardDetails,
      cardName: cardName,
    };
    this.ScorecardService.changeMessage(data);
  }
  ngOnDestroy(): void {
    this.ScorecardService.changeMessage("nodata");
  }
}

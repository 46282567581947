<div class="accordion" id="accordionExample">
    <div class="accordion-item" *ngFor="let objective of OKRList; let i = index">
        <h2 class="accordion-header" id="heading{{i}}">
            <button class="accordion-button" 
                 [attr.data-bs-target]="'#collapse'+i"
                aria-expanded="true" [attr.aria-controls]="'collapse' + i">
                <span><b>{{objective.title}}</b> <br>
                    <span *ngIf="objective.end_date">End Date: {{objective.end_date | date:
                        'mediumDate'}}</span><br>
                    <span *ngIf="objective?.primary_owners?.length > 0"><b>Primary Owners :</b>
                         <span *ngFor="let user of objective?.primary_owners;let memIndx=index">
                            <span *ngIf="memIndx == 0">{{user?.first_name}} {{user?.last_name}}</span>
                            <span *ngIf="memIndx != 0">, {{user?.first_name}} {{user?.last_name}}</span>
                        </span></span><br>
                    <span *ngIf="objective?.support_owners?.length > 0"><b>Secondary Owners :</b> 
                        <span *ngFor="let user of objective?.support_owners;;let memIndx=index">
                            <span *ngIf="memIndx == 0">{{user?.first_name}} {{user?.last_name}}</span>
                            <span *ngIf="memIndx != 0">, {{user?.first_name}} {{user?.last_name}}</span>
                        </span></span>    
                </span>
            </button>
        </h2>
        <div id="collapse{{i}}" class="accordion-collapse"
            [attr.aria-labelledby]="'heading'+i" [attr.data-bs-parent]="'#accordionExample'">
            <div class="accordion-body">
                <div class="accordion" id="accordion{{i}}">
                    <div class="accordion-item"
                        *ngFor="let kr of objective?.key_results; let j = index">
                        <h2 class="accordion-header " id="heading{{i}}{{j}}">
                            <button class="accordion-button"
                            
                                [attr.data-bs-target]="'#collapse' + i + j" aria-expanded="true"
                                [attr.aria-controls]="'collapse' + i + j">
                                <span><b>{{kr.title}}</b> <br>
                                    <span *ngIf="kr.end_date">End Date: {{kr.end_date | date:
                                        'mediumDate'}}</span><br>
                                    <span *ngIf="kr?.primary_owners?.length > 0"><b>Primary Owners :</b> 
                                        <span *ngFor="let user of kr?.primary_owners;let memIndx=index">
                                            <span *ngIf="memIndx == 0">{{user?.first_name}} {{user?.last_name}}</span>
                                            <span *ngIf="memIndx != 0">, {{user?.first_name}} {{user?.last_name}}</span>
                                        </span>
                                    </span><br>
                                    <span *ngIf="kr?.support_owners?.length > 0"><b>Secondary Owners :</b> 
                                        <span *ngFor="let user of kr?.support_owners;let memIndx=index">
                                            <span *ngIf="memIndx == 0">{{user?.first_name}} {{user?.last_name}}</span>
                                            <span *ngIf="memIndx != 0">, {{user?.first_name}} {{user?.last_name}}</span>
                                        </span></span>
                                </span>
                            </button>
                        </h2>
                        <div id="collapse{{i}}{{j}}" class="accordion-collapse"
                            [attr.aria-labelledby]="'heading' + i + j"
                            [attr.data-bs-parent]="'#accordion' + i">
                            <div class="accordion-body">
                                <div *ngFor="let goal of kr?.goals; let k = index">
                                    <div  *ngIf="goal?.goal_name"
                                        style="padding: 10px; position: relative; background: #f2f2f2; border-radius: 2px; margin-bottom: 10px;">
                                        <div *ngIf="!goal.edit">
                                            <span style="display: block;font-size: 13px; "><b>{{goal.goal_name}}</b></span>
                                            <span style="display: block;font-size: 12px;padding-left: 10px; font-style: italic">{{goal.goal_description}}
                                                <span *ngIf="goal.end_date"><br> End Date:
                                                    {{goal.end_date | date: 'mediumDate'}}</span>
                                            </span>
                                            <div style="background-color: #e0dbdb;padding: 4px;">
                                            <p style="font-size: 12px; font-weight: 500; "> No. of projects contributing - {{goal?.project_count}}</p>
                                            <div *ngIf="goal?.instancemetrics?.length > 0" class="custom-table fixed-table-header">
                                                <table class="table table-bordered text-left">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>MOS</th>
                                                            <th>No. of Projects</th>
                                                            <th>Aggregated Value</th>
                                                            <th>Target Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let mositem of goal?.instancemetrics;let y = index">
                                                            <td style="font-size:12px;">{{y+1}}. </td>
                                                            <td>{{mositem?.instancemetrics__name}}</td>
                                                            <td>{{mositem?.project_count}}</td>
                                                            <td>{{mositem?.aggregate_value}}</td>
                                                            <td>{{mositem?.target_value}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RiskConvergenceService {
  constructor(private http: HttpClient) {}

  getRiskFormData() {
    return this.http.get(
      `project-dynamic-form/?form_name=delivery_risk_management&flag=master`
    );
  }
  addMeasureRisk(path: any, uuid: any, payload: any) {
    return this.http.post(`delivery/${path}/${uuid}/`, payload, {
      responseType: "blob",
    });
  }
  addCommentRisk(uuid: any, payload: any) {
    return this.http.post(
      `delivery/add-update-comments-to-risk/${uuid}/`,
      payload
    );
  }
  updateCommentRisk(uuid: any, comment_id: any, payload: any) {
    return this.http.post(
      `delivery/add-update-comments-to-risk/${uuid}/comment_id=${comment_id}`,
      payload
    );
  }
  deleteCommentRisk(comment_id: any) {
    return this.http.delete(`delivery/delete-risk-comment/${comment_id}/`);
  }
  createCustomRiskDomain(payload: any) {
    return this.http.post(`delivery/create-update-risk-domain/`, payload);
  }
  createCustomRiskSubDomain(payload: any) {
    return this.http.post(`delivery/create-update-risk-subdomain/`, payload);
  }
  createCustomRisk(payload: any) {
    return this.http.post(`delivery/create-update-risk/`, payload);
  }
  deleteRisk(uuid: any, subdomain_uuid: any) {
    return this.http.delete(`delivery/delete-risk/${uuid}/${subdomain_uuid}/`);
  }
  downloadDocument(path: any, uuid: any, payload: any) {
    return this.http.post(`delivery/${path}/${uuid}/`, payload, {
      responseType: "blob",
    });
  }
  getRiskHeatMap(
    portId?: any,
    subPortId?: any,
    initiative_type?: any,
    projectId?: any
  ) {
    let params = "";
    if (portId) {
      params = `portfolio=${portId}`;
    }
    if (subPortId) {
      params = `${params}&portfolio=${subPortId}`;
    }
    if (initiative_type) {
      params = `${params}&initiative_type=${initiative_type}`;
    }
    if (projectId) {
      params = `${params}&project=${projectId}`;
    }
    return this.http.get(`delivery/risk-heat-map/?${params}`);
  }

  getHeatMapProjects(projectId: number) {
    return this.http.get(`delivery/risk-heat-map-projects/${projectId}/`, {
      observe: "response",
    });
  }

  getAllHeatMapProjects(
    portId?: any,
    subPortId?: any,
    initiative_type?: any,
    projectId?: any
  ) {
    let params = "";
    if (portId) {
      params = `portfolio=${portId}`;
    }
    if (subPortId) {
      params = `${params}&portfolio=${subPortId}`;
    }
    if (initiative_type) {
      params = `${params}&initiative_type=${initiative_type}`;
    }
    if (projectId) {
      params = `${params}&project=${projectId}`;
    }
    return this.http.get(`delivery/risk-heat-map-projects-list/?${params}`);
  }

  projectList() {
    return this.http.get("delivery/project-list?flag=mapped_list");
  }

  getClarificationList() {
    return this.http.get("delivery/get-certification/");
  }

  getVendorList() {
    return this.http.get(
      "dynamic-form/?form_name=opportunity_empanelled_vendors"
    );
  }

  addCertification(payload: any) {
    return this.http.post("delivery/add-update-certification/", payload);
  }

  getClarificationListBygdp_id(id: any) {
    return this.http.get(`delivery/get-certification/?gdp_id=${id}`);
  }

  deleteCertificateByid(id: any) {
    return this.http.delete("delivery/delete-certification/" + id);
  }
  updateInvestment(data: any, certificate_id: any) {
    return this.http.post(
      "delivery/add-update-certification/?certificate_id=" + certificate_id,
      data
    );
  }
  getvendordetails() {
    return this.http.get("action-tracker/get-vendor-details");
  }
  deleteMeasureofSuccess(id: any) {
    return this.http.delete(`delivery/delete-risk-measureofsuccess/${id}/`);
  }
  deleteMeasureofSuccessDoc(id: any, payload: any) {
    return this.http.post(
      `delivery/risk-measureofsuccess-file-delete/${id}/`,
      payload
    );
  }

  getRiskMaster() {
    return this.http.get(`delivery/gettrisks/list/`);
  }

  getDetailByRiskId(id: any) {
    return this.http.get(`delivery/project-filtered-list/?risk=${id}`);
  }

  getRiskProject(queryParam: any) {
    return this.http.get(`delivery/get-risk-goal-project/?${queryParam}`);
  }

  getRiskApplication(queryParam: any) {
    return this.http.get(`delivery/get-unique-application-list/?${queryParam}`);
  }

  updateRisk(payload: any) {
    return this.http.post(`delivery/create-domain-risk/`, payload);
  }

  getRiskTracker(offSet?: any, end?: any) {
    let params = `offset=${offSet}&end=${end}`;
    return this.http.get(`delivery/risk/details/?${params}`);
  }

  getFilteredRiskTracker(offSet?: any, end?: any, queryParams?: any) {
    let params = `offset=${offSet}&end=${end}${queryParams}`;
    return this.http.get(`delivery/risk/details/?${params}`);
  }

  downloadTrackerList(queryParam: any) {
    return this.http.get(
      `delivery/risk/details/?response_type=csv${queryParam}`,
      { responseType: "blob" }
    );
  }

  getRiskPulse() {
    return this.http.get("/nps/pulses/?performance_impact=Risk");
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OkrConvergenceService {

  constructor(private http: HttpClient,private transferState:TransferState) { }

  deleteComment(deleteId: any) {
    return this.http.delete(`opportunity/delete-key-results-comment/${deleteId}/`, { observe: 'response' });
  }

  postUpdateComment(commentId: any, CommentMsg: any) {
    return this.http.post(`opportunity/add-update-comments-to-key-results/${commentId}/`, CommentMsg, { observe: 'response' });
  }

  getOkrHeatMap(portId?: any, subPortId?: any, initiative_type?: any, projectId?: any) {
    let params = '';
    if (portId) {
      params = `portfolio=${portId}`
    } if (subPortId) {
      params = `${params}&portfolio=${subPortId}`
    }
    if (initiative_type) {
      params = `${params}&initiative_type=${initiative_type}`
    }
    if (projectId) {
      params = `${params}&project=${projectId}`
    }
    return this.http.get(`delivery/okr-heat-map/?${params}`)
  }

  getHeatMapProjects(projectId: number) {
    return this.http.get(`delivery/okr-heat-map-projects/${projectId}/`, { observe: 'response' });
  }

  getAllHeatMapProjects(portId?: any, subPortId?: any, initiative_type?: any, projectId?: any) {
    let params = '';
    if (portId) {
      params = `portfolio=${portId}`
    } if (subPortId) {
      params = `${params}&portfolio=${subPortId}`
    }if (initiative_type) {
      params = `${params}&initiative_type=${initiative_type}`
    }
    if (projectId) {
      params = `${params}&project=${projectId}`
    }
    return this.http.get(`delivery/okr-heat-map-projects-list/?${params}`);
  }

  projectList() {
    return this.http.get('delivery/project-list?flag=mapped_list');
  }

  getKeyResultsRecords(query?: any) {
    return this.http.get(`dynamic-form/?form_name=${query}` + `&flag=master`);
  }
  addMeasureofSuccess(uuid: any, payload: any) {
    return this.http.post(`opportunity/add-measure-of-success-to-key-results/${uuid}/`, payload);
  }
  downloadDocument(uuid: any, payload: any) {
    return this.http.post(`opportunity/key-result-measure-of-success-file-download/${uuid}/`, payload, { responseType: 'blob' });
  }
  getFrequencyValues() {
    return this.http.get(`dynamic-form/kr_measure_of_success_frequency`);
  }
  getUnitValues() {
    return this.http.get(`dynamic-form/kr_measure_of_success_unit`);
  }
  getAllMetrics(searchKey?:string){
    let params= searchKey ? '?search_key='+searchKey : '';
    return this.http.get(`instance-metrics/get-global-metrics/${params}`);
  }
  saveMetricDetails(payload:any){
    return this.http.post(`instance-metrics/`,payload);
  }
  updateMetricDetails(id:any,payload:any){
    return this.http.put(`instance-metrics/${id}/`,payload);
  }  
  deleteMeasureofSuccess(id:any){
    return this.http.delete(`delivery/delete-okr-measureofsuccess/${id}/`);
  }
  deleteMeasureofSuccessDoc(id:any,payload:any){
    return this.http.post(`delivery/okr-measureofsuccess-file-delete/${id}/`,payload);
  }
  /*TOE*/
  
  createUpdateToECategory(payload:any){
    return this.http.post(`opportunity/create-update-term-category/`,payload);
  }
  createUpdateToESubCategory(payload:any){
    return this.http.post(`opportunity/create-update-term-subcategory/`,payload);
  }  
  createUpdateToE(payload:any){
    return this.http.post(`opportunity/create-update-term/`,payload);
  }
  getFunctionsList() {    
    const detailKey = makeStateKey(`get-action-function`);
    const cachedResponse = this.transferState.get(detailKey, null);
    if (!cachedResponse) {
      return this.http.get('action-tracker/get-action-function/').pipe(
        tap((res:any) => this.transferState.set(detailKey, res))
      )
    }
    return of(cachedResponse);
  }
  
  getToEMasters() {
    return this.http.get('dynamic-form/?form_name=terms_of_engagement&flag=master');
  }
  getTermMasters() {      
    const detailKey = makeStateKey(`opportunity/get-term-masters/`);
    const cachedResponse = this.transferState.get(detailKey, null);
    if (!cachedResponse) {
      return this.http.get('opportunity/get-term-masters/').pipe(
        tap((res:any) => this.transferState.set(detailKey, res))
      )
    }
    return of(cachedResponse);
  }

  getGoalMaster(queryparam:any) {
    return this.http.get(`delivery/getgoals/list/?${queryparam}`)
  }

  getMosMaster(){
    return this.http.get(`metrics/metric-value/okr_metrics`)
  }

  getDetailByGoalId(id: any){
    return this.http.get(`delivery/project-filtered-list/?goal=${id}`)
  }

  getGoalOpp(queryParam: any){
    return this.http.get(`delivery/get-goal-opportunity/?${queryParam}`)
  }

  getGoalProject(queryParam: any){
    return this.http.get(`delivery/get-risk-goal-project/?${queryParam}`)
  }

  getGoalApplication(queryParam: any){
    return this.http.get(`delivery/get-unique-application-list/?${queryParam}`)
  }

  updateOkr(payload: any){
    return this.http.post(`delivery/create-okr-goal/`, payload)
  }

  createMosValue(payload: any, id: any = ''){
    if(id){
      return this.http.post(`delivery/measure_of_success/`, payload)
    }else{
      return this.http.post(`delivery/measure_of_success/`, payload)
    }
    
  }

  deleteMosValue(id:any){
    return this.http.delete(`delivery/measure_of_success/${id}`);
  }
  getUser() {
    return this.http.get(`users/user-list/`);
  }
  getBaseValue(type:any){
    return this.http.get(`base_value/?type=${type}`);
  }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AvatarModule } from "ngx-avatar";
import { NgxPermissionsModule } from "ngx-permissions";
import { SwiperModule } from "swiper/angular";
import { AutocompleteTextboxComponent } from "./components/autocomplete-textbox/autocomplete-textbox.component";
import { CarouselComponent } from "./components/carousel/carousel.component";
import { DatatableComponent } from "./components/datatable/datatable.component";
import { HeaderComponent } from "./components/header/header.component";
import { LeftSideMenuComponent } from "./components/left-side-menu/left-side-menu.component";
import { ListComponent } from "./components/list/list.component";
import { ModalComponent } from "./components/modal/modal.component";
import { SelectComponent } from "./components/select/select.component";
import { TableCardComponent } from "./components/table-card/table-card.component";
import { TabmenuComponent } from "./components/tabmenu/tabmenu.component";
import { TipsComponent } from "./components/tips/tips.component";
import { MaterialmodulesModule } from "./materialmodules.module";
/* import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'; */
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatSliderModule } from "@angular/material/slider";
import { TransferState } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { NgbModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { ChartModule } from "angular-highcharts";
import { HighchartsChartModule } from "highcharts-angular";
import { NgCircleProgressModule } from "ng-circle-progress";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { QuillModule } from "ngx-quill";
import { TimeagoModule } from "ngx-timeago";
import { PowerBIEmbedModule } from "powerbi-client-angular";
import { BreadCrumbComponent } from "./components/bread-crumb/bread-crumb.component";
import { ChatBotWindowComponent } from "./components/chat-bot-window/chat-bot-window.component";
import { ComingSoonDialog } from "./components/coming-soon/coming-soon-dialog";
import { CommentsComponent } from "./components/comments/comments.component";
import { ConfirmModalComponent } from "./components/confirm-modal/confirm-modal.component";
import { DeletePopupComponent } from "./components/delete-popup/delete-popup.component";
import { FilterCardComponent } from "./components/filter-card/filter-card.component";
import { FooterComponent } from "./components/footer/footer.component";
import { GnewsComponent } from "./components/gnews/gnews.component";
import { LabelInputComponent } from "./components/label-input/label-input.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { ModalHeaderCommonComponent } from "./components/modal-header-common/modal-header-common.component";
import { OkrListingComponent } from "./components/okr-insight/okr-listing/okr-listing.component";
import { OwnerComponent } from "./components/owner/owner.component";
import { RightPanelStatementDetailsComponent } from "./components/right-panel-statement-details/right-panel-statement-details.component";
import { RisklogHeatmapComponent } from "./components/risklog-heatmap/risklog-heatmap.component";
import { SharedScopeComponent } from "./components/scope/scope.component";
import { IndividualScorecardComponent } from "./components/scorecard-matrix/individual-scorecard/individual-scorecard.component";
import { ScorecardComponent } from "./components/scorecard-matrix/scorecard/scorecard.component";
import { SelectionTreeListingComponent } from "./components/selection-tree-listing/selection-tree-listing.component";
import { SideMenuComponent } from "./components/side-menu/side-menu.component";
import { StatementResponseDetailsComponent } from "./components/statement-response-details/statement-response-details.component";
import { ConfirmationDialogComponent } from "./components/tag/confirmation-dialog/confirmation-dialog.component";
import { TagComponent } from "./components/tag/tag.component";
import { TagMappedProjectsComponent } from "./components/tags-mapped-projects/tags-mapped-projects.component";
import { TicketComponent } from "./components/ticket/ticket.component";
import { TreeListingComponent } from "./components/tree-listing/tree-listing.component";
import { TreeComponent } from "./components/tree/tree.component";
import { WorkgroupComponent } from "./components/workgroup/workgroup.component";
import { PipeModule } from "./pipes/pipe.module";
import { TreefilterPipe } from "./pipes/treefilter.pipe";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { PowerBiModuleComponent } from "./power-bi-module/power-bi-module.component";
import { PulseComponent } from "./components/pulse/pulse.component";

@NgModule({
  declarations: [
    DatatableComponent,
    HeaderComponent,
    FooterComponent,
    ComingSoonDialog,
    ModalComponent,
    ListComponent,
    TipsComponent,
    SelectComponent,
    LeftSideMenuComponent,
    SideMenuComponent,
    AutocompleteTextboxComponent,
    CarouselComponent,
    TabmenuComponent,
    FilterCardComponent,
    TableCardComponent,
    TreeComponent,
    TreeListingComponent,
    SelectionTreeListingComponent,
    GnewsComponent,
    TreefilterPipe,
    DeletePopupComponent,
    BreadCrumbComponent,
    TicketComponent,
    TagComponent,
    ConfirmationDialogComponent,
    LoadingComponent,
    ConfirmModalComponent,
    ChatBotWindowComponent,
    LabelInputComponent,
    OwnerComponent,
    WorkgroupComponent,
    CommentsComponent,
    ScorecardComponent,
    IndividualScorecardComponent,
    RightPanelStatementDetailsComponent,
    StatementResponseDetailsComponent,
    RisklogHeatmapComponent,
    ModalHeaderCommonComponent,
    TagMappedProjectsComponent,
    SharedScopeComponent,
    OkrListingComponent,
    PowerBiModuleComponent,
    PulseComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxDatatableModule,
    FormsModule,
    NgxPermissionsModule,
    AvatarModule,
    SwiperModule,
    MaterialmodulesModule,
    NgbModule,
    NgCircleProgressModule.forRoot({}),
    Ng2SearchPipeModule,
    PipeModule,
    NgbPaginationModule,
    QuillModule,
    ChartModule,
    HighchartsChartModule,
    DragDropModule,
    PowerBIEmbedModule,
    MatSliderModule,
  ],
  exports: [
    DatatableComponent,
    HeaderComponent,
    FooterComponent,
    ComingSoonDialog,
    ListComponent,
    TipsComponent,
    SelectComponent,
    TableCardComponent,
    LeftSideMenuComponent,
    SideMenuComponent,
    AutocompleteTextboxComponent,
    CarouselComponent,
    TabmenuComponent,
    FilterCardComponent,
    TreeComponent,
    TreeListingComponent,
    SelectionTreeListingComponent,
    GnewsComponent,
    Ng2SearchPipeModule,
    AvatarModule,
    DeletePopupComponent,
    BreadCrumbComponent,
    PipeModule,
    TicketComponent,
    NgbPaginationModule,
    TagComponent,
    LoadingComponent,
    LabelInputComponent,
    OwnerComponent,
    WorkgroupComponent,
    TimeagoModule,
    CommentsComponent,
    ScorecardComponent,
    IndividualScorecardComponent,
    RightPanelStatementDetailsComponent,
    StatementResponseDetailsComponent,
    RisklogHeatmapComponent,
    ModalHeaderCommonComponent,
    TagMappedProjectsComponent,
    OkrListingComponent,
    SharedScopeComponent,
    PdfViewerModule,
    MatCheckboxModule,
    PowerBiModuleComponent,
    PulseComponent,
  ],
  providers: [TransferState],
  entryComponents: [ConfirmationDialogComponent],
})
export class SharedModule {}

<div class="tag" [ngClass]="isReadOnly ? 'read-only' : ''">
  <div class="row">
    <div class="col-md-12">
      <p class="tag-holder mb-0">
        <mat-chip-list
          #chipTagList
          aria-label="tag selection"
          class="mat-mar-left"
        >
          <div *ngIf="slices == 0">
            <mat-chip
              *ngFor="let tag of associatedTags"
              [matMenuTriggerFor]="tagMenu"
            >
              <span class="tag-icon"> <i class="far fa-tag"></i> </span>
              <span class="tag-name">
                <span
                  style="font-size: 12px; font-weight: 100; font-style: initial"
                  >{{ tag.tag_category }}: </span
                >{{ tag.tag_name }}
              </span>
            </mat-chip>
          </div>
          <div *ngIf="slices > 0 && associatedTags.length > 0">
            <mat-chip
              *ngFor="let tag of associatedTags | slice : 0 : slices"
              [matMenuTriggerFor]="tagMenu"
            >
              <span class="tag-icon"> <i class="far fa-tag"></i> </span>
              <span class="tag-name">
                <span
                  style="font-size: 12px; font-weight: 100; font-style: initial"
                  >{{ tag.tag_category }}: </span
                >{{ tag.tag_name }}
              </span>
            </mat-chip>
            <span style="font-size: 10px"
              >+{{ associatedTags.length - 1 }} More</span
            >
          </div>
          <mat-chip
            *ngIf="associatedTags.length <= 0"
            [matMenuTriggerFor]="tagMenu"
          >
            <span class="tag-icon"> <i class="far fa-tag"></i> Tags: </span>
            <span class="tag-name"> No tags yet</span>
          </mat-chip>
        </mat-chip-list>
        <mat-menu #tagMenu="matMenu">
          <div
            class="tag-pop"
            (click)="stopPropagation($event); $event.stopPropagation()"
            (keydown)="stopPropagation($event); $event.stopPropagation()"
          >
            <div class="p-2">
              <mat-chip-list
                #chipTagListDetail
                aria-label="tag selection"
                class="mb-2"
              >
                <mat-chip
                  *ngFor="let tag of associatedTags"
                  (removed)="removeTag(tag, $event)"
                >
                  {{ tag.tag_name }}
                  <button matChipRemove *ngIf="!user?.is_vendor || vendorEdit">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
              </mat-chip-list>
              <p *ngIf="associatedTags.length <= 0" class="no-tag m-0">
                No tag added yet
              </p>
            </div>
            <div
              class="search-input-holder"
              *ngIf="!user?.is_vendor || vendorEdit"
            >
              <input
                type="text"
                class="search-key"
                placeholder="Search or Create new"
                matInput
                [(ngModel)]="tagInput"
                (ngModelChange)="searchInput($event)"
                (keyup.enter)="addTag(null)"
                (keyup)="navigateUsingKey($event)"
              />
            </div>
            <div class="flex-container" *ngIf="!user?.is_vendor || vendorEdit">
              <mat-chip
                *ngIf="selectedCategory && filteredCategoryList.length == 0"
                class="selectedTag"
              >
                {{ selectedCategory.tag_category }}
              </mat-chip>
              <mat-chip
                [ngClass]="
                  selectedCategory &&
                  selectedCategory.tag_category == tag.tag_category
                    ? 'selectedTag'
                    : 'unselectedTag'
                "
                *ngFor="let tag of filteredCategoryList"
                (click)="selectCategory(tag)"
              >
                {{ tag.tag_category }}
              </mat-chip>
            </div>
            <ul
              *ngIf="
                filteredCategoryList?.length > 0 &&
                filteredList?.length > 0 &&
                (!user?.is_vendor || vendorEdit)
              "
              class="p-0"
            >
              <li
                *ngFor="let item of filteredList; let i = index"
                [class.active]="checkSelected(item)"
                [class.activeSearchLink]="i === linkIndex"
                class="tag-item"
                (click)="addTag(item)"
              >
                {{ item.tag_name }}
                <i
                  class="fa fa-trash delete-item"
                  (click)="deleteTag(item); $event.stopPropagation()"
                ></i>
              </li>
            </ul>
            <div
              *ngIf="
                (!selectedCategory ||
                  filteredList?.length == 0 ||
                  filteredCategoryList?.length == 0) &&
                (!user?.is_vendor || vendorEdit)
              "
              class="mt-2 mb-2 tag-not-found"
            >
              <span *ngIf="filteredList?.length == 0" style="margin: 10px">
                Press Enter to create a new tag
              </span>
              <span
                *ngIf="!selectedCategory && filteredCategoryList?.length > 0"
                style="margin: 10px"
              >
                Please Select a category to view tag
              </span>
              <span
                *ngIf="filteredCategoryList?.length == 0"
                style="margin: 10px; cursor: pointer"
              >
                Category you are searching not available.
                <span *ngIf="selectedCategory">
                  Press Enter to create Tag in selected category.
                </span>
                <span *ngIf="!selectedCategory">
                  Press Enter to create Tag in default category.
                </span>
              </span>
            </div>
          </div>
        </mat-menu>
      </p>
    </div>
  </div>
</div>

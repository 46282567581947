import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TermsService {

  constructor(private http: HttpClient) { }

  createCustomTerms(payload: any) {
    return this.http.post(`opportunity/create-custom-measurement-criteria/`, payload, { observe: 'response' });
  }

  uploadFile(payload: any, id: any) {
    return this.http.post(`opportunity/terms-file-upload/${id}/`, payload);
  }

  getFile(id: any) {
    return this.http.get(`opportunity/terms-file-list/${id}/`);
  }

  deleteFile(payload: any, id: any) {
    return this.http.post(`opportunity/terms-file-delete/${id}/`, payload);
  }

  downloadFile(payload: any, id: any) {
    return this.http.post(`opportunity/terms-file-download/${id}/`, payload, { responseType: 'blob' });
  }
  deleteCategory(uuid: any) {
    return this.http.delete(`opportunity/delete-term-category/${uuid}`)
  }
  deleteSubcategory(uuid: any, cateUuid: any) {
    return this.http.delete(`opportunity/delete-term-subcategory/${uuid}/${cateUuid}/`)
  }
  deleteMeasurement(uuid: any, subUuid: any) {
    return this.http.delete(`opportunity/delete-term-measurement-criteria/${uuid}/${subUuid}/`)
  }
  updateTerms(payload:any){
    return this.http.post(`terms/category/`, payload)
  }
  getTermsMaster(){
    return this.http.get(`terms/category/`)
  }

  saveSubcategory(payload:any){
    return this.http.post(`terms/subcategory/`, payload)
  }

  saveTermcategory(payload:any){
    return this.http.post(`terms/term/`, payload)
  }
  getBaseValue(type:any){
    return this.http.get(`base_value/?type=${type}`);
  }

  getMosMaster(){
    return this.http.get(`metrics/metric-value/okr_metrics`)
  }
  createMosValue(payload: any, id: any = ''){
      return this.http.post(`terms/toe-metrics/`, payload)
  }
  deleteMosValue(id:any){
    return this.http.delete(`terms/toe-metrics/${id}`);
  }

  getTermMetrics(projectId:any,term_Id:any){
    return this.http.get(`delivery/get-term-metrics?project_id=${projectId}&term_id=${term_Id}`);
  }
  setTermMetrics(payload:any){
    return this.http.post(`delivery/term-metrics/`, payload)
  }
  getTermEvidence(term_Id:any){
    return this.http.get(`delivery/project-evidence?projectterm_id=${term_Id}`);
  }
  createTermEvidence(payload:any){
    return this.http.post(`delivery/project-evidence/`,payload);
  }
}

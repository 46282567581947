import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { MessageService } from "../../services/message.service";
import { TurnkeyTrackerService } from "../../services/turnkey-tracker.service";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-owner",
  templateUrl: "./owner.component.html",
  styleUrls: ["./owner.component.scss"],
})
export class OwnerComponent {
  @Input() inputString: any;
  @Input() inputType: any;
  @Output() refereshParentData = new EventEmitter<string>();
  @Input() editable: boolean = true;
  @Input() ownerdata: any;
  @Input() context: any = "";
  @Input() allowOneInput: boolean = false;

  oldData: any;
  owners: any;
  selectedOwners: any = [];

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  editMode: boolean = false;

  constructor(
    private cookieService: CookieService,
    private messageService: MessageService,
    private turnkeyTrackerService: TurnkeyTrackerService
  ) {}

  ngOnInit() {
    this.getOwner();

    if (!this.cookieService.get("owner")) {
      const myDate: Date = new Date();
      myDate.setSeconds(myDate.getSeconds() + 10);

      this.cookieService.set("owner", "true", { expires: myDate });
      this.getAllOwner();
    }
  }

  async getOwner() {
    let t = this;
    this.messageService.getOwner().subscribe((res: any) => {
      if (res.length > 0) {
        t.selectedOwners = [];
        t.owners = res;
        if (t.ownerdata) {
          t.ownerdata.forEach((element: any) => {
            t.owners.forEach((elm: any) => {
              if (elm.id === element.user) {
                t.selectedOwners.push(elm);
              }
            });
          });
        }
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
      }
    });
  }

  private _filter(value: any): any {
    if (typeof value === "string") {
      const filterValue = value?.toLowerCase();
      let t = this;

      let removeSelected = this.owners.filter(function (el: any) {
        return !t.selectedOwners.includes(el);
      });

      return removeSelected.filter((owner: any) =>
        owner?.email?.toLowerCase().includes(filterValue)
      );
    }
  }

  getAllOwner() {
    this.turnkeyTrackerService.getOwner().subscribe((res: any) => {
      this.messageService.setOwner(res);
    });
  }

  addOwner(e: any) {
    if (this.allowOneInput) {
      if (this.selectedOwners.length === 0) {
        this.selectedOwners.push(e);
        this.myControl.setValue("");
        this.sendToParent();
      } else {
        this.myControl.setValue("");
        this.sendToParent();
        return;
      }
    } else {
      this.selectedOwners.push(e);
      this.myControl.setValue("");
      this.sendToParent();
    }
  }

  getIconNameShort(obj: any) {
    return (
      (obj.first_name ? obj.first_name.substring(0, 1) : "") +
      (obj.last_name ? obj.last_name.substring(0, 1) : "")
    ).toUpperCase();
  }

  removeUser(i: any) {
    this.selectedOwners.splice(i, 1);
    this.sendToParent();
  }

  sendToParent() {
    let sel: any = [];
    this.selectedOwners.forEach((element: any) => {
      sel.push(element.id);
    });

    this.refereshParentData.emit(sel);
  }

  cancelEdit() {
    this.inputString = this.oldData;
    this.editMode = false;
  }
}

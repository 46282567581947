export class Calculator {
  data: any;
  values: any;

  constructor(data: any) {
    this.data = data;
    this.values = {};
  }

  calculate() {
    // Populate the initial values from default
    for (const [key, value] of Object.entries(this.data.default)) {
      this.values[key] = value;
    }
  }

  private calculateMetric(formula: string): number | null {
    // Calculate value and return the value
    try {
      const result = new Function(
        "values",
        `with (values) { return ${formula}; }`
      )(this.values);
      return result;
    } catch (e) {
      console.error(`Error in formula ${formula}:`, e);
      return null;
    }
  }

  changeMetric(metricName: string, metricValue: number) {
    this.values[metricName] = metricValue;
    try {
      const dependents = this.data.derived_metrics[metricName].dependents;
      for (const dependent of dependents) {
        const calculated_value = this.calculateMetric(
          this.data.derived_metrics[dependent].formula
        );
        if (calculated_value !== null) {
          this.changeMetric(dependent, calculated_value);
        }
      }
    } catch (e) {
      console.error(
        "Metric Name",
        metricName,
        "Data",
        this.data.derived_metrics,
        e
      );
      throw e;
    }
  }
}

<div class="workgroup-flex">
  <span *ngIf="showLabel"> Workgroup: </span>
  <div
    class="iholder"
    [matMenuTriggerFor]="menu"
    [ngClass]="{ disabled: !editable }"
  >
    <mat-icon *ngIf="selectedWorkgroup.length <= 0">group_work</mat-icon>
    <span
      *ngFor="
        let sg of selectedWorkgroup | slice : 0 : showCount;
        let i = index
      "
      class="wg-sp"
    >
      {{ sg.name }}<span *ngIf="i != selectedWorkgroup.length - 1">, </span>
    </span>
    <span *ngIf="selectedWorkgroup.length > showCount">
      +{{ selectedWorkgroup.length - showCount }}
    </span>
  </div>
</div>

<mat-menu #menu="matMenu" class="my-menu">
  <div (click)="$event.stopPropagation()" *ngIf="workgroup">
    <form class="example-form">
      <mat-form-field class="example-full-width" style="width: 100%">
        <input
          type="text"
          placeholder="Search workgroup"
          matInput
          [formControl]="myControl"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="addWorkGroup($event.option.value)"
        >
          <mat-option
            *ngFor="let option of filteredOptions | async"
            [value]="option"
          >
            <b>{{ option.name }}</b> <br />
            {{ option.mail }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>

    <div class="selected-holder">
      <div
        *ngFor="let sel of selectedWorkgroup; let i = index"
        class="selected-item"
      >
        <div>
          <span style="font-weight: 700">{{ sel.name }}</span> <br />
          <span style="font-size: 10px">{{ sel.mail }}</span>
        </div>
        <button
          mat-icon-button
          color="warn"
          (click)="removeWorkGroup(i)"
          class="small-icon-button"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
  </div>
</mat-menu>

import { Component, Input, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { Observable } from "rxjs";

import { Breadcrumb } from "../../model/breadcrumb.model";
import { BreadcrumbService } from "../../services/breadcrumb.service";
import { Router } from "@angular/router";
import * as textConfiguration from "src/assets/static-text-configuration.json";
import { ViewEncapsulation } from "@angular/core";
import { MessageService } from "../../services/message.service";
@Component({
  selector: "app-bread-crumb",
  templateUrl: "./bread-crumb.component.html",
  styleUrls: ["./bread-crumb.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class BreadCrumbComponent {
  staticText: any = (textConfiguration as any).default;
  url: any;
  breadcrumbs$: Observable<Breadcrumb[]>;
  iframeEmbedded: boolean = false;

  @Input() showColorBlue: any;
  constructor(
    private breadcrumbService: BreadcrumbService,
    private _location: Location,
    private router: Router,
    private messageService: MessageService
  ) {
    this.messageService.getIframeData().subscribe((res: any) => {
      this.iframeEmbedded = typeof res != "object" ? true : false;
    });
    this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$;

    this.url = this.router.url;
  }

  goBack() {
    this._location.back();
  }
}

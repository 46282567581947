import { Component, OnInit,Input } from '@angular/core';
import { OkrConvergenceService } from 'src/app/shared/services/okr-convergence.service';

@Component({
  selector: 'app-okr-listing',
  templateUrl: './okr-listing.component.html',
  styleUrls: ['./okr-listing.component.scss']
})
export class OkrListingComponent implements OnInit {
  @Input() OKRList: any;
  mosList:any;
  constructor(private okrConvergenceService: OkrConvergenceService) {
   }

  ngOnInit(): void {
    console.log(this.OKRList);
  }

  getMos(){
    this.okrConvergenceService.getMosMaster().subscribe((res: any) => {
      this.mosList = res;
    })
  }
  getMosInfo(item: any){
    return this.mosList.filter((e: any) => e.id === item)[0]
  }
}

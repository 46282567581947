import { NgModule } from '@angular/core';
import { DateAgoPipe } from './date-ago.pipe';
import { GetRandomColorPipe } from './get-random-color.pipe';
import { GetFilesizePipe } from './get-filesize.pipe';
import { ConvertDataStringPipe } from './convert-data-string.pipe';
import { FilterPortfoliosPipe } from './filter-portfolios.pipe';
import { ReplaceCharacterPipe } from './replace-character.pipe';
import { JoinArrayValuesPipe } from './join-array-values.pipe';
import { SafeUrlPipe } from './safe-url.pipe';


@NgModule({
  imports: [],
  declarations: [DateAgoPipe, GetRandomColorPipe, GetFilesizePipe, ConvertDataStringPipe, FilterPortfoliosPipe,ReplaceCharacterPipe, JoinArrayValuesPipe, SafeUrlPipe],
  exports: [DateAgoPipe,GetRandomColorPipe,GetFilesizePipe,ConvertDataStringPipe,FilterPortfoliosPipe,ReplaceCharacterPipe,JoinArrayValuesPipe, SafeUrlPipe]
})

export class PipeModule {

  static forRoot() {
    return {
      ngModule: PipeModule,
      providers: [],
    };
  }
} 
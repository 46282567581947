<div class="pe-0 my-2">
  <div class="d-flex align-items-center justify-content-between">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label
        >{{ items.display_name }} <sup class="text-danger">*</sup></mat-label
      >
      <mat-select
        placeholder="Select from the list"
        appearance="filled"
        class="w-100"
        [ngClass]="{ 'is-invalid': submitted && f[items.field_name].errors }"
        (selectionChange)="dataSelect($event)"
      >
        <mat-option
          *ngFor="let i of items.field_values"
          [value]="i.id"
          style="font-weight: 400; line-height: 24px"
        >
          {{ i.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<mat-error *ngIf="submitted && f[items.field_name].errors">
  <small class="text-danger" *ngIf="f[items.field_name].errors.required"
    >{{ items.display_name }} is required</small
  >
</mat-error>

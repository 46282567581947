import { AfterViewInit, Component, OnInit } from '@angular/core';
import {NotificationsService } from 'src/app/shared/services/notifications.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-chat-bot-window',
  templateUrl: './chat-bot-window.component.html',
  styleUrls: ['./chat-bot-window.component.scss']
})
export class ChatBotWindowComponent implements OnInit {
  queryName:any=''
  answerList:any = [];
  myColorVaraible = '#B4B7BF';
  emptyTextResult = 'Please enter message...';
  loading:boolean = false;
  answerKeyList:any = [];
  tokenJWT: any;
  constructor(
    private NotificationsService: NotificationsService,
    private spinner: NgxSpinnerService,
    private sanitize: DomSanitizer,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.getToken();
  }

  getToken(){
    if(!this.cookieService.get('busterJWT')){
      this.loading = true;
      this.NotificationsService.getJWTtoken().subscribe((res:any)=>{
        const myDate: Date = new Date();
        myDate.setHours( myDate.getHours() + 3 );
  
        this.tokenJWT = this.sanitize.bypassSecurityTrustResourceUrl('https://app.buster.so/embed/51c23e6a-1b02-426f-a5e9-f9a0541b0f37?jwtToken=' + res.jwt_token)
        this.cookieService.set( 'busterJWT', res.jwt_token, myDate );
        setTimeout(() => {
          this.loading = false;
        }, 400)
      })
    }else{
      this.tokenJWT = this.sanitize.bypassSecurityTrustResourceUrl('https://app.buster.so/embed/51c23e6a-1b02-426f-a5e9-f9a0541b0f37?jwtToken=' + this.cookieService.get('busterJWT'))
    }
  }

  getQueryList(){
    if(this.queryName){
      this.loading = true;
   
      this.NotificationsService.getChatBotQueryList(this.queryName).subscribe((res:any)=>{
        if(res.answer){
          if(res?.answer?.error){
            this.emptyTextResult = res?.answer?.error;
            this.loading = false;
            return;
          }
          this.answerList = res.answer;
          this.answerKeyList = this.answerList.length > 0 ? Object.keys(this.answerList[0]) : [];
          this.loading = false;
        }else{
          this.emptyTextResult = 'No records found try with other message...';
          this.loading = false;
        }
      }, (error: any) => {
        this.loading = false;
        this.emptyTextResult = 'No records found try with other message...';
        throw error;
      });
      if(this.answerList.length == 0){
        this.emptyTextResult = '';
      }
    }

    
  }
  checkMessage(event:any){
    event.preventDefault();
  }

  getAnswerListKeys(ans:any): string[] {
    return Object.keys(ans);
  }

  textEnterStarted(){
    this.answerList = [];
    this.answerKeyList = [];
    if(this.queryName){
      this.myColorVaraible = "green";
      this.emptyTextResult = 'Please hit submit button to start search...';
    }else{
      this.myColorVaraible = "#B4B7BF";
      this.emptyTextResult = 'Please enter message...';
    }
  }

  clearMessage(){
    this.queryName = "";
  }

  alterKeyName(key:any){
    return key.replace(/_/g, ' ');
  }
}

<div class="nested-accordion">
  <div *ngFor="let item of items; let indx = index" class="nested-accordion-loop">
    <div class="accordion-item" *ngIf="(item.level != 'goal' && item.level != 'term' && item.level != 'risk') && ((context?.componentName?.name=='PreviewComponent' && item.selected) || !context?.componentName)">
      <div class="accordion-header" (click)="toggleAccordion(item, indx) && $event.stopPropagation()" [ngStyle]="{'backgroundColor': item.expanded ? getColor(item) : ''}">
        <div class="title-hol" [ngStyle]="{'flex' : fromPage == 'toe' ? 'auto' : '', 'align-items' : fromPage == 'toe' ? 'center' : ''}">
          <input #checkbox type="checkbox" [checked]="item.selected" [disabled]="disabled" (change)="item.selected = !item.selected; selectGoals(item);" (click)="$event.stopPropagation()">
          <span class="log-number">{{indx + 1}}</span>
          <input type="text" class="form-control" [(ngModel)]="item.name" *ngIf="fromPage == 'toe' && item.id == ''">
          <div class="title" *ngIf="fromPage == 'toe' && item.id != ''"> {{ item.name }}</div>
          <div class="title" *ngIf="fromPage != 'toe'"> {{ item.name }}</div>
          <div *ngIf="fromPage == 'toe' && (item.level == 'category' || item.level == 'subcategory') && !user?.is_vendor">
            <mat-icon aria-hidden="false" aria-label="material-icons-filled" (click)="add(item) && $event.stopPropagation()" *ngIf="item.id != ''"
              class="cursor-pointer fs-5 px-3">add
            </mat-icon>
            <mat-icon aria-hidden="false" aria-label="material-icons-filled" (click)="save(item) && $event.stopPropagation()" *ngIf="item.id == ''"
                  class="material-icons-outlined cursor-pointer fs-5 px-3">save
            </mat-icon>
          </div>
        </div>

        <div class="formParentArrow p-2 d-flex align-items-center">
          <span class="material-icons-outlined arrow-icon" *ngIf="!item.expanded">
              keyboard_arrow_down
          </span>
          <span class="material-icons-outlined arrow-icon" *ngIf="item.expanded">
              keyboard_arrow_up
          </span>
      </div>
      </div>
      <div class="accordion-content" [hidden]="!item.expanded">
        <app-loading *ngIf="loading"></app-loading>
        <app-selection-tree-listing [tree]="tree" *ngIf="item.children" [disabled]="disabled" [(items)]="item.children" [context]="context" [fromPage]="fromPage"  [(frequencyList)]="frequencyList" [(userList)]="userList"></app-selection-tree-listing>
      </div>
    </div>
    <div *ngIf="(item.level == 'goal' || item.level == 'term' || item.level == 'risk') && ((context?.componentName?.name=='PreviewComponent' && item.selected) || !context?.componentName)" class="hov">
      <div [ngStyle]="{'backgroundColor': getColor(item)}" class="last-level" [ngStyle]="{'display': fromPage == 'toe' ? 'block' : 'inherit'}">
        <div style="display: flex; align-items: flex-start;"  (click)="item.selected = disabled ? item.selected : !item.selected; selectGoals(item);">
          <input #checkboxes type="checkbox" [checked]="item.selected" [disabled]="disabled" (change)="item.selected = !item.selected; selectGoals(item);" (click)="$event.stopPropagation()">
          <div class="leveltext" *ngIf="fromPage != 'toe'">{{item?.goal_name || item?.name}} </div>
          <div class="leveltext" *ngIf="fromPage == 'toe' && item.id != ''">{{item?.goal_name || item?.name}} </div>
          <input type="text"  class="form-control" [(ngModel)]="item.name" *ngIf="fromPage == 'toe' && item.id == ''">
          <div *ngIf="fromPage == 'toe'">
            <mat-icon aria-hidden="false" aria-label="material-icons-filled" (click)="saveTerm(item) && $event.stopPropagation()" *ngIf="item.id == ''"
                  class="material-icons-outlined cursor-pointer fs-5 px-3">save xys
              </mat-icon>
          </div>

           <mat-icon *ngIf="item?.is_recomended" matTooltip="Recommended"
           matTooltipClass="example-tooltip" style="margin-top:-8px;color:rgb(255, 184, 5)">star</mat-icon>
        </div>
        <div *ngIf="fromPage == 'toe'" class="comment-input-sec">
          <div style="font-size:14px;margin-top: 5px;" >
            <div class="row">
              <div class="col-md-6">
                <div class="row" *ngIf="item?.function">
                    <div class="col-md-4">
                      <b>Function :</b> 
                    </div>
                    <div class="col-md-8">
                      {{item?.function?.display_name}}
                    </div>
                </div>
                <div class="row" *ngIf="item?.fulfilment_by">
                  <div class="col-md-4">
                    <b>Fulfilled By :</b> 
                  </div>
                  <div class="col-md-8">
                    {{item?.fulfilment_by}}
                  </div>
                </div>
                <div class="row" *ngIf="item?.evidence">
                  <div class="col-md-4">
                    <b>Evidence :</b> 
                  </div>
                  <div class="col-md-6">
                    {{item?.evidence?.display_name}}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row" *ngIf="item?.domain">
                    <div class="col-md-6">
                      <b>Domain :</b> 
                    </div>
                    <div class="col-md-6">
                      {{item?.domain}}
                    </div>
                </div>
                <div class="row" *ngIf="item?.defination_of_done">
                  <div class="col-md-6">
                    <b>Definition of done :</b> 
                  </div>
                  <div class="col-md-6">
                    {{item?.defination_of_done}}
                  </div>
              </div>
            </div>
          </div>
          </div><br>
          <!-- <label>Context</label> -->
          <div *ngIf="fromPage == 'toe' && item.selected">
          <div class=" d-flex">
          <mat-form-field appearance="outline" class="w-25 ml-1">
            <mat-label>Context</mat-label>
            <input matInput  [value]="item?.context" (click)="$event.stopPropagation()" (keyup)="emitCommentValue($event,item,'comments')" placeholder="Context" >
          </mat-form-field>
          
          <mat-form-field appearance="outline" class="w-25 ml-1">
            <mat-label>Frequency</mat-label>
            
            <mat-select  (click)="$event.stopPropagation()" [(ngModel)]="item.frequency" (selectionChange)="emitCommentValue($event,item,'frequency')">
              <mat-option *ngFor="let fun of frequencyList"   [value]="fun.id" >{{fun?.display_name}} </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-25 ml-1">
            <mat-label>Due Date</mat-label>
            
            <input matInput 
              [matDatepicker]="picker" readonly class="fw-500"
              [value]="item.due_date" (dateChange)="emitCommentValue($event,item,'due_date')"
               (click)="$event.stopPropagation();picker.open();">
              <mat-datepicker-toggle matSuffix [for]="picker">
              </mat-datepicker-toggle>
              <mat-datepicker #picker>
              </mat-datepicker>
          </mat-form-field>
        </div>
        <!-- Project metrics and evidence -->
        <div class="d-flex justify-content-center">
          <button mat-button mat-flat-button class="bg-primary text-whtie mr-4" (click)="onClickMetrics(item, MetricsPopUp)" color="primary">
            Metrics
          </button>
          <button mat-button mat-flat-button class="bg-primary text-whtie" (click)="onClickEvidence(item, EvidencePopUp)" color="primary">
            Evidence
          </button>
        </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>




<!-- Metrics Update -->
<ng-template #MetricsPopUp>
  <div class="modal-dialog m-0" style="max-width: 100%">
    <div class="modal-content" style="border: none">
      <app-modal-header-common [title]="'Metrics Update'">
      </app-modal-header-common>
      <div class="modal-body p-0">
        <div class="saw">
          <div class="d-flex mb-2" style="align-items: center">
            <span class="sjec obj">Category: </span>

            <p class="mb-0">{{ termDetails?.category }}</p>
          </div>
          <div class="d-flex mb-2" style="align-items: center">
            <span class="sjec krq">Sub Category: </span>

            <p class="mb-0">{{ termDetails?.subcategory }}</p>
          </div>
          <div class="d-flex mb-2" style="align-items: center">
            <span class="sjec gol">Term: </span>
            <p class="mb-0">{{ termDetails?.name }}</p>
          </div>
          <div class="d-flex mb-2" style="align-items: center">
            <span class="sjec gol" style="background: #0f7b68"
              >Metrics:
            </span>
            <mat-form-field style="font-size: 12px">
              <mat-label></mat-label>
              <mat-select
                [(ngModel)]="selectedMetric"
                (selectionChange)="selectedMatricEvent($event)"
              >
                <mat-option>Select Metric</mat-option>
                <mat-option *ngFor="let mos of termDetails?.instancemetrics" value="{{ mos.id }}">{{ mos?.instancemetrics__name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="d-flex mb-2" style="align-items: center" *ngIf="selectedMetricObj">
            <span class="sjec gol" style="background: #0f7b68"
              >Year:
            </span>

            <mat-form-field style="font-size: 12px">
              <mat-label></mat-label>
              <mat-select
                [(ngModel)]="selectedPeriod" (selectionChange)="selectedMatricYear($event)"
              >
                <mat-option>Select Period</mat-option>
                <mat-option value="2024">2024</mat-option>
                <mat-option value="2023">2023</mat-option>
                <mat-option value="2022">2022</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="selectedMetricObj && selectedPeriod" class="custom-table table-responsive p-4 pt-0" style="height: auto">
            <div class="d-flex justify-content-between">
              <span>Target Value : {{selectedMetricObj?.target_value}} </span>
              <button type="submit" class="btn btn-primary ml-2 mb-2" (click)="matricTarVal()">Submit</button>
            </div>
              <table class="table table-bordered text-center">
                <thead style="position: sticky; top: 0; z-index: 1">
                  <tr>
                    <th class="fixed-header" scope="col">Month</th>
                    <th class="fixed-header" scope="col">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let m of monthList;let i = index;">
                    <td>{{m.month}}</td>
                    <td style="padding: 5px;" >
                      <div class="d-flex justify-content-center" *ngIf="m.edit">
                        <input type="text"  [(ngModel)]="m.value" class="form-control" placeholder="Enter Number" style="font-size: 12px;width: 100px;">
                        <!-- <button type="submit" class="btn btn-primary ml-2" (click)="matricTarVal(i)">Submit</button> -->
                      </div>
                      <div class="d-flex justify-content-between" *ngIf="!m.edit">
                        <div></div>
                        <span>{{m?.value || 0}}</span>
                        <i class="fa fa-edit" (click)="editColum(i)"></i>
                      </div>
                    </td>
                  </tr>
                 
                </tbody>
              </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Metrics Update End -->

<!-- Evidence Update -->

<ng-template #EvidencePopUp>
  <div class="modal-dialog m-0" style="max-width: 100%">
    <div class="modal-content" style="border: none">
      <app-modal-header-common [title]="'Evidence Update'">
      </app-modal-header-common>
      <div class="modal-body p-0">
        <div class="saw">
          <div class="d-flex mb-2" style="align-items: center">
            <span class="sjec obj">Evidence: </span>

            <p class="mb-0">{{ termDetails?.evidence?.display_name }}</p>
          </div>
          <div class="d-flex mb-2" style="align-items: center">
            <span class="sjec gol" style="background: #0f7b68"
              >Compliance:
            </span>
            <mat-form-field style="font-size: 12px">
              <mat-label></mat-label>
              <mat-select [(ngModel)]="selectedCompliance">
                <mat-option>Select Compliance</mat-option>
                <mat-option  value="Met">Met</mat-option>
                <mat-option value="NotMet">Not Met</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="selectedCompliance == 'Met'">
            <div class="d-flex">
              <mat-form-field  class="w-25">
                <mat-label>Date</mat-label>
                <input matInput [(ngModel)]="selectedDate"
                  [matDatepicker]="picker" readonly class="fw-500"
                   (click)="picker.open();" />
                  <mat-datepicker-toggle matSuffix [for]="picker">
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker>
                  </mat-datepicker>
              </mat-form-field>
              <mat-form-field  class="w-25 ml-4">
                <mat-label>Owner</mat-label>
                <mat-select   [(ngModel)]="selectedOwner" >
                  <mat-option *ngFor="let user of userList" [value]="user.id">{{user?.first_name}} {{user?.last_name}}</mat-option> 
                </mat-select>
              </mat-form-field>
              <mat-form-field  class="ml-4">
                <mat-label>Comment</mat-label>
                <input matInput [(ngModel)]="selectedComment"  placeholder="Comment" />
              </mat-form-field>
              
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <mat-label style="font-size:14px;">Upload Document *</mat-label><br>
                  <button mat-icon-button color="success">
                      <input hidden #fileInput type="file" id="file" (change)="onFileSelected($event)">
                      <mat-icon (click)="fileInput.click()">cloud_upload</mat-icon>
                  </button>
                  <span>{{documentFilename}}</span>
              </div>
            </div>
            <div class="float-right">
              <button type="submit" class="btn btn-primary ml-2 mb-2" (click)="saveEvidence()">Submit</button>
            </div>
          </div>

          <div class="custom-table table-responsive pt-4" style="height: auto">
            <h6>Summary</h6>
              <table class="table table-bordered text-center">
                <thead style="position: sticky; top: 0; z-index: 1">
                  <tr>
                    <th class="fixed-header" scope="col">Date</th>
                    <th class="fixed-header" scope="col">Owner</th>
                    <th class="fixed-header" scope="col">Comment</th>
                    <th class="fixed-header" scope="col">Evidence</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let det of termEvidenceList">
                    <td>{{det?.date}}</td>
                    <td>{{det?.owner?.first_name}} {{det?.owner?.last_name}}</td>
                    <td>{{det?.comment}}</td>
                    <td><a href="{{det.url}}">{{det?.file_name}}</a></td>
                  </tr>
                  <tr *ngIf="termEvidenceList?.length == 0">
                    <td colspan="4">No data found</td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!--Evidence Update end-->

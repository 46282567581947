<div *ngIf="pulsesList.length">
  <div class="flex-container-1">
    <div
      class="flex-item p-1 hubble-card"
      *ngFor="let x of pulsesList; let i = index"
    >
      <div
        class="trn-x"
        [ngStyle]="{ background: x.color }"
        style="height: 260px"
        (click)="showEditPulseDialog(x)"
      >
        <div [matTooltip]="x.title" class="mb-0 trn-nm w-75 text-truncate">
          {{ x.title }}
        </div>
        <div class="mb-0 mt-2 trn-ph" *ngIf="x.created_by">
          <div class="d-flex align-items-center">
            <mat-icon class="small-icon mr-1">perm_identity</mat-icon>
            <span>Owner</span>
          </div>
          <span style="display: flex; font-weight: 700">
            {{ x.created_by.first_name + " " + x.created_by.last_name }}
          </span>
        </div>
        <p class="mb-0 mt-2 trn-ph">
          Created On
          <span style="display: flex; font-weight: 700">
            {{ (x.start_date | date : "dd MMM, yyyy") || "-" }}
          </span>
        </p>
        <p class="mb-0 mt-2 trn-ph">
          Due By
          <span style="display: flex; font-weight: 700">
            {{ (x.end_date | date : "dd MMM, yyyy") || "-" }}
          </span>
        </p>
        <div class="mb-0 mt-2 trn-ph">
          <div
            *ngIf="x.is_positive"
            class="font align-items-center gap-2"
            style="display: flex; font-weight: 700"
          >
            <span class="positive-pulse"> </span>
            <span> positive </span>
          </div>
          <div
            *ngIf="!x.is_positive"
            class="font align-items-center gap-2"
            style="display: flex; font-weight: 700"
          >
            <span class="negative-pulse"> </span>
            <span> negative </span>
          </div>
        </div>
        <div class="mb-0 mt-2 trn-ph">
          <div class="d-flex align-items-center">
            <span class="font">Performance Impact</span>
          </div>
          <span class="font" style="display: flex; font-weight: 700">
            {{ x.impact }}
          </span>
        </div>
        <button
          mat-icon-button
          class="action-vert"
          color="warn"
          (click)="deletePulse(x.id, x.name); $event.stopPropagation()"
        >
          <mat-icon style="font-size: 15px; color: red">delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
